<div class="gallery">
  @for (element of elements; track trackByFn.bind(this)($index, element)) {
    <div class="gallery-tile">
      <ng-template [ngTemplateOutlet]="galleryViewTemplate" [ngTemplateOutletContext]="{ $implicit: element }"></ng-template>
    </div>
  }

  <!-- These placeholders are used to block less than the possible number of elements in a row from expanding -->
  <!-- The 3 elements are calculated from the max width of the page (1680 currently) / min element width (375px) = ~4 elements. -->
  <!-- As the placeholders are only needed if there is at least one element in one row we only need 3 placeholders. -->
  <div class="element-placeholder"></div>
  <div class="element-placeholder"></div>
  <div class="element-placeholder"></div>
</div>
