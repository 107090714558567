import { BreakpointObserver } from '@angular/cdk/layout';
import { Component, ContentChild, EventEmitter, Input, Output, TemplateRef } from '@angular/core';

@Component({
  selector: 'portal-gallery-view',
  templateUrl: './gallery-view.component.html',
  styleUrls: ['./gallery-view.component.scss'],
})
export class GalleryViewComponent<T> {
  @Input() public elements: T[];
  @ContentChild(TemplateRef) public templateRef: TemplateRef<unknown>;
  @Input() public galleryViewTemplate: TemplateRef<unknown>;
  @Input() public trackByPropertyName: string;
  @Output() public fadeInDone: EventEmitter<void> = new EventEmitter<void>();

  constructor(private _breakpointObserver: BreakpointObserver) {}

  public trackByFn(index: number, element: T): string {
    return this.trackByPropertyName ? element[this.trackByPropertyName] : index;
  }
}
