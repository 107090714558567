@if (itemCategoryTreeService.treeFlatDataSource.data.length > 0) {
  <mat-tree [dataSource]="itemCategoryTreeService.treeFlatDataSource" [treeControl]="itemCategoryTreeService.treeControl">
    <mat-tree-node *matTreeNodeDef="let node" matTreeNodeToggle matTreeNodePadding>
      <button mat-icon-button disabled></button>
      <mat-checkbox
        class="checklist-leaf-node"
        color="primary"
        [checked]="itemCategoryTreeService.checklistSelection.isSelected(node)"
        (change)="itemCategoryTreeService.categoryLeafSelectionToggle(node)"
      >
        {{ node.CategoryName }}
      </mat-checkbox>
    </mat-tree-node>
    <mat-tree-node *matTreeNodeDef="let node; when: itemCategoryTreeService.hasChild" matTreeNodePadding>
      <button mat-icon-button matTreeNodeToggle>
        <mat-icon>
          {{ itemCategoryTreeService.treeControl.isExpanded(node) ? 'expand_more' : 'chevron_right' }}
        </mat-icon>
      </button>
      <mat-checkbox
        [checked]="itemCategoryTreeService.descendantsAllSelected(node)"
        [indeterminate]="itemCategoryTreeService.descendantsPartiallySelected(node)"
        (change)="itemCategoryTreeService.categorySelectionToggle(node)"
        (click)="$event.stopPropagation()"
        color="primary"
      >
        {{ node.CategoryName }}
      </mat-checkbox>
    </mat-tree-node>
  </mat-tree>
}
