<div class="filter-wrapper">
  <ng-content></ng-content>

  @if (showCategoryFilter) {
    <button [matMenuTriggerFor]="filterMenu" [matTooltip]="translationService.translations.tooltips.FilterItemCategory.toString()" mat-raised-button>
      <mat-icon [svgIcon]="'enthus-account_tree'"></mat-icon>
    </button>
    <mat-menu #filterMenu>
      <portal-item-category-tree></portal-item-category-tree>
      <mat-divider></mat-divider>
      <div class="filter-actions">
        <button (click)="filterCategories(true)" mat-stroked-button>
          {{ translationService.translations.action.Clear }}
        </button>
        <button (click)="filterCategories()" mat-raised-button>
          {{ translationService.translations.action.Filter }}
        </button>
      </div>
    </mat-menu>
  }

  @if (showAddButton) {
    <ng-template [ngTemplateOutlet]="addButtonTemplate"></ng-template>
  }
  @if (showContextualSearch) {
    <portal-contextual-search #contextualSearch (searchTermChange)="getItems$.next({ searchTerm: $event })" [noFilter]="true"> </portal-contextual-search>
  }
</div>

<ng-container
  *ngTemplateOutlet="(this.mobileMode$ | async) === false || showOnlyGalleryView ? tabGroupTemplate : listView; context: { $implicit: (dataItems$ | async) }"
></ng-container>

@if (!!lastSearchTerm && (dataItems$ | async)?.length <= 0) {
  <portal-marker-sketch
    portalHideWhileLoading
    [align]="'center'"
    [svgIcon]="'enthus-search_off'"
    [infoMessage]="translationService.translations.information.NoFilterResult.toString()"
  ></portal-marker-sketch>
} @else if (lastSearchTerm === '' && (dataItems$ | async)?.length <= 0) {
  <portal-marker-sketch
    portalHideWhileLoading
    [align]="'center'"
    [svgIcon]="'enthus-playlist_remove'"
    [infoMessage]="translationService.translations.list.ListIsEmpty.toString()"
  ></portal-marker-sketch>
}

<ng-template #tabGroupTemplate let-dataItems>
  @if (!((dataItems$ | async)?.length <= 0 && hideTabBar)) {
    <mat-tab-group
      mat-align-tabs="start"
      mat-stretch-tabs="false"
      [backgroundColor]="'primary'"
      [color]="'primary'"
      [selectedIndex]="
        showOnlyGalleryView
          ? ListViewMode.GALLERY
          : authenticationService.authenticationData?.IsReadOnlySession
            ? selectedTabIndex
            : (settingsService.settingsChange$ | async)?.TabIndex
      "
      (animationDone)="tabChangeAnimationDone(tabGroup)"
      (selectedTabChange)="loadingService.isLoading$.next(true)"
      [disablePagination]="true"
      [dynamicHeight]="false"
      #tabGroup
      [class.hide-tab-header]="hideTabBar"
    >
      <mat-tab>
        <ng-template mat-tab-label>
          <div [matTooltip]="translationService.translations.document.GalleryView.toString()">
            <mat-icon [svgIcon]="'enthus-view_module'"></mat-icon>
          </div>
        </ng-template>
        <ng-template matTabContent>
          <div #galleryWrapper cdkScrollable class="gallery-wrapper">
            <portal-gallery-view
              class="show-more-gallery-view"
              [elements]="dataItems"
              [galleryViewTemplate]="galleryViewTemplate"
              [trackByPropertyName]="trackByPropertyName"
              [class.isMobile]="(appService.isMobile$ | async) === true"
            ></portal-gallery-view>
            <ng-template [ngTemplateOutletContext]="{ $implicit: dataItems }" [ngTemplateOutlet]="showMoreButton"></ng-template>
          </div>
        </ng-template>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <div [matTooltip]="translationService.translations.document.ListView.toString()">
            <mat-icon [svgIcon]="'enthus-view_list'"></mat-icon>
          </div>
        </ng-template>
        <ng-template matTabContent>
          <ng-template [ngTemplateOutlet]="listView" [ngTemplateOutletContext]="{ $implicit: dataItems }"></ng-template>
        </ng-template>
      </mat-tab>
      <mat-tab>
        <ng-template mat-tab-label>
          <div [matTooltip]="translationService.translations.document.TableView.toString()">
            <mat-icon [svgIcon]="'enthus-grid_on'"></mat-icon>
          </div>
        </ng-template>
        <ng-template matTabContent>
          @if (currentTab === ListViewMode.TABLE) {
            <ng-template [ngTemplateOutlet]="tableViewTemplate" [ngTemplateOutletContext]="{ $implicit: dataItems }"></ng-template>
          }
        </ng-template>
      </mat-tab>
    </mat-tab-group>
  }
</ng-template>

<ng-template #listView let-dataItems>
  <mat-action-list
    class="show-more-list-view"
    [class.mobile]="(appService.isMobile$ | async) === true"
    [class.portrait]="(appService.isPortrait$ | async) === true"
    cdkScrollable
    disableRipple
    #matActionList
  >
    @for (dataItem of dataItems; track trackByFunction.bind(this)($index, dataItem)) {
      <ng-template [ngTemplateOutlet]="listViewTemplate" [ngTemplateOutletContext]="{ $implicit: dataItem }"></ng-template>
    }
    <ng-template [ngTemplateOutlet]="showMoreButton" [ngTemplateOutletContext]="{ $implicit: dataItems }"></ng-template>
  </mat-action-list>
</ng-template>

<ng-template #showMoreButton let-dataItems>
  @if (dataItems?.length && (tabGroup?.first?.selectedIndex === currentTab || (mobileMode$ | async) === true)) {
    <div class="show-more">
      @if (totalRowCount > 0 && totalRowCount > dataItems?.length) {
        <button mat-raised-button color="primary" (click)="getItems$.next({ showMore: true })" [disabled]="(loadingService.isLoading$ | async) === true">
          {{ translationService.translations.action.ShowMore }}
          <mat-icon [svgIcon]="'enthus-arrow_expand'"></mat-icon>
        </button>
      }
      @if (totalRowCount <= dataItems?.length && dataItems?.length > 0 && showBackToTopButton) {
        <div class="back-to-top">
          <button mat-raised-button color="primary" (click)="scrollToTop()">
            {{ translationService.translations.action.BackToStartOfShowMoreList }}
            <mat-icon [svgIcon]="'enthus-expand_less'"></mat-icon>
          </button>
        </div>
      }
    </div>
  }
</ng-template>
