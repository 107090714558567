/* eslint-disable max-lines */
import { CdkScrollable } from '@angular/cdk/overlay';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ContentChild,
  ElementRef,
  EventEmitter,
  HostBinding,
  HostListener,
  Input,
  OnDestroy,
  OnInit,
  QueryList,
  TemplateRef,
  ViewChild,
  ViewChildren,
} from '@angular/core';
import { MatTab, MatTabGroup, MatTabHeader } from '@angular/material/tabs';
import { ActivatedRoute, NavigationEnd, NavigationStart, Router } from '@angular/router';
import { GridComponent } from '@syncfusion/ej2-angular-grids';
import { BehaviorSubject, merge, Observable, of, Subject, Subscription, throwError } from 'rxjs';
import { concatMap, debounceTime, filter, finalize, map, switchMap, take, takeUntil, tap } from 'rxjs/operators';
import { GalleryViewComponent } from '../gallery-view/gallery-view.component';
import { IGetItemsConfig, IShowMoreDataItem } from '../interfaces/show-more.interface';
import { AppService, DeviceType, ListViewMode, Orientation } from '../services/app.service';
import { AuthenticationService } from '../services/authentication.service';
import { DropdownValuesService } from '../services/dropdown-values.service';
import { ICategoryNode } from '../interfaces/dropdown-values.interface';
import { ErrorHandlingService } from '../services/error-handling.service';
import { SettingsService } from '../services/settings.service';
import { ItemCategoryTreeService } from '../services/item-category-tree.service';
import { TranslationService } from '../services/translation.service';
import { UnsavedChangesService } from '../services/unsaved-changes.service';
import { SideSheetComponent } from '../side-sheet/side-sheet.component';
import { LoadingService } from '../services/loading.service';

@Component({
  selector: 'portal-show-more',
  templateUrl: './show-more.component.html',
  styleUrls: ['./show-more.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ShowMoreComponent<T> implements OnDestroy, AfterViewInit, OnInit {
  public dataItems$ = new BehaviorSubject([]);
  @Input() public dataItems: T[];
  @Input() @HostBinding('style.position') public position: 'absolute' | 'relative' = 'absolute';
  @Input() public hideTabBar = false;
  @ContentChild('galleryViewTemplate') public galleryViewTemplate: TemplateRef<never>;
  @ContentChild('listViewTemplate') public listViewTemplate: TemplateRef<never>;
  @ContentChild('tableViewTemplate') public tableViewTemplate: TemplateRef<never>;
  @ContentChild('addButtonTemplate') public addButtonTemplate: TemplateRef<never>;
  public totalRowCount: number;
  @Input() public fetchItemsErrorMessage: string;
  @Input() public showContextualSearch = true;
  @Input() public showCategoryFilter = false;
  @Input() public showAddButton = false;
  @Input() public showOnlyGalleryView = false;
  @Input() public itemSearchFunction$: <T>(searchTerm: string, limit: number, categoryFilter?: string[]) => Observable<IShowMoreDataItem<T>>;
  @Input() public sideSheet: SideSheetComponent;
  @Input() public gridHasLimit = true;
  public getItems$: Subject<IGetItemsConfig> = new Subject<IGetItemsConfig>();
  public lastSearchTerm = '';
  @ViewChildren('galleryWrapper', { read: CdkScrollable }) public galleryWrapperScrollable: QueryList<CdkScrollable>;
  @ViewChildren('matActionList', { read: CdkScrollable }) public matActionListScrollable: QueryList<CdkScrollable>;
  // eslint-disable-next-line @typescript-eslint/naming-convention
  public ListViewMode = ListViewMode;
  @ViewChildren(GridComponent) public grid: QueryList<GridComponent>;
  @ViewChild(GalleryViewComponent) public galleryView: QueryList<GalleryViewComponent<T>>;
  @ViewChildren('tabGroup') public tabGroup: QueryList<MatTabGroup>;
  public currentTab: ListViewMode;
  public showBackToTopButton = false;
  public reloadData$: EventEmitter<void> = new EventEmitter<void>();
  @Input() public trackByPropertyName: string;
  private _destroyEmitter: EventEmitter<void> = new EventEmitter<void>();
  private _previousSelectedTabIndex: number = this.settingsService.settingsChange$.value?.TabIndex;
  public selectedTabIndex: number = this.settingsService.settingsChange$.value?.TabIndex;
  private _lastListScrollOffset = 0;
  private _lastGalleryScrollOffset = 0;
  private _lastSyncfusionGridScrollOffset = 0;
  private _scrollCacheRoute: string = this._router.url.split('?')[0];
  private _searchTermChanged = false;
  private _interceptTabChangeSubscription: Subscription;
  private _queryParamsCategories = this._activatedRoute.snapshot.queryParams.categories;
  private _isFetchingData$ = new BehaviorSubject<boolean>(true);
  public mobileMode$: Observable<boolean>;

  constructor(
    public translationService: TranslationService,
    public appService: AppService,
    private _errorHandlingService: ErrorHandlingService,
    public settingsService: SettingsService,
    private _activatedRoute: ActivatedRoute,
    private _router: Router,
    private _dropdownValuesService: DropdownValuesService,
    private _elementRef: ElementRef,
    private _cd: ChangeDetectorRef,
    private _unsavedChangesService: UnsavedChangesService,
    public authenticationService: AuthenticationService,
    private _itemCategoryTreeService: ItemCategoryTreeService,
    public loadingService: LoadingService
  ) {
    this._router.events
      .pipe(
        takeUntil(this._destroyEmitter),
        filter((routerEvent) => routerEvent instanceof NavigationStart || routerEvent instanceof NavigationEnd),
        tap((routerEvent) => {
          if (routerEvent instanceof NavigationStart && routerEvent.url.includes(this._scrollCacheRoute + '/')) {
            const syncfusionGridContent = (this._elementRef.nativeElement as HTMLElement).querySelector('ejs-grid .e-content');
            if (syncfusionGridContent) {
              this._lastSyncfusionGridScrollOffset = syncfusionGridContent.scrollTop;
            }

            const syncfusionTreegridContent = (this._elementRef.nativeElement as HTMLElement).querySelector('ejs-treegrid .e-content');
            if (syncfusionTreegridContent) {
              this._lastSyncfusionGridScrollOffset = syncfusionTreegridContent.scrollTop;
            }
          } else if (routerEvent instanceof NavigationEnd) {
            this.restoreScrollPosition(routerEvent.url);
          }
        })
      )
      .subscribe();
  }

  public ngOnInit(): void {
    this.mobileMode$ = this.appService.typeAndOrientation$.pipe(
      map((typeAndOrientation) => {
        return (
          typeAndOrientation.type === DeviceType.HANDSET ||
          (typeAndOrientation.type === DeviceType.TABLET && typeAndOrientation.orientation === Orientation.PORTRAIT)
        );
      })
    );

    this.loadingService.isLoading$.next(true);

    this.appService.typeAndOrientationChange$
      .pipe(
        takeUntil(this._destroyEmitter),
        filter(
          () =>
            ((this.appService.changedFromDesktopToHandset || this.appService.changedFromTabletToHandset) && this.currentTab === ListViewMode.TABLE) ||
            ((this.appService.changedFromHandsetToDesktop || this.appService.changedFromHandsetToTablet) && this.currentTab === ListViewMode.TABLE)
        ),
        tap(() => this.dataItems$.next([]))
      )
      .subscribe(() => this.getItems$.next({}));

    merge(this.reloadData$, this.getItems$)
      .pipe(
        takeUntil(this._destroyEmitter),
        concatMap((value, index) => {
          if (index === 0) {
            return this._dropdownValuesService.dropdownValues$.pipe(
              takeUntil(this._destroyEmitter),
              filter((dropdownValues) => !!dropdownValues),
              take(1),
              map((x) => x.ItemCategories),
              tap((itemCategories: ICategoryNode[]) => (this._itemCategoryTreeService.treeFlatDataSource.data = itemCategories)),
              switchMap(() => of(value))
            );
          } else {
            return of(value);
          }
        }),
        concatMap((value) => {
          if (!!this._activatedRoute.snapshot.queryParams && !!this._queryParamsCategories) {
            return this._router
              .navigate([], {
                queryParamsHandling: 'merge',
                queryParams: this._activatedRoute.snapshot.queryParams,
                relativeTo: this._activatedRoute,
                replaceUrl: true,
                state: { skipFade: true },
              })
              .then(() => {
                this._itemCategoryTreeService.treeControl.dataNodes?.forEach((dataNode) => {
                  if (this._queryParamsCategories.includes(dataNode.CategoryPathName)) {
                    this._itemCategoryTreeService.checklistSelection.select(dataNode);
                  }
                });

                return value;
              });
          } else {
            return of(value);
          }
        }),
        switchMap((data: IGetItemsConfig | void) => this._getItems(data ? data?.searchTerm : undefined, data ? data?.showMore : undefined)),
        finalize(() => {
          this.loadingService.isLoading$.next(false);
        })
      )
      .subscribe();

    if (!this.itemSearchFunction$) {
      this.getItems$.next({});
    }
  }

  public ngAfterViewInit(): void {
    this.matActionListScrollable.changes
      .pipe(
        takeUntil(this._destroyEmitter),
        filter((changes) => changes.first),
        tap(() => this.checkIfScrollable()),
        switchMap((changes) => changes.first.elementScrolled().pipe(debounceTime(50))),
        tap(() => (this._lastListScrollOffset = this.matActionListScrollable.first.measureScrollOffset('top')))
      )
      .subscribe();

    this.galleryWrapperScrollable.changes
      .pipe(
        takeUntil(this._destroyEmitter),
        filter((changes) => changes.first),
        tap(() => this.checkIfScrollable()),
        switchMap((changes) => changes.first.elementScrolled().pipe(debounceTime(50))),
        tap(() => (this._lastGalleryScrollOffset = this.galleryWrapperScrollable.first.measureScrollOffset('top')))
      )
      .subscribe();

    if (this.tabGroup?.first) {
      this.tabGroup.first._handleClick = this._interceptTabChange.bind(this);
    }
  }

  private _interceptTabChange(_tab: MatTab, _tabHeader: MatTabHeader, index: number): Subscription {
    if (this._interceptTabChangeSubscription) {
      this._interceptTabChangeSubscription.unsubscribe();
    }

    this._interceptTabChangeSubscription = of(this._unsavedChangesService.hasUnsavedChanges())
      .pipe(
        takeUntil(this._destroyEmitter),
        switchMap((hasChanges: boolean) => {
          if (hasChanges) {
            return this._unsavedChangesService.confirmUnsavedChanges();
          } else {
            return of(true);
          }
        }),
        filter((confirmed) => confirmed),
        tap(() => {
          this.selectedTabIndex = index;

          if (this.sideSheet) {
            this.sideSheet.close(true);
          }
          this.loadingService.isLoading$.next(true);
          this.settingsService.saveTabIndex('TabIndex', index);

          if (index === ListViewMode.TABLE || this._previousSelectedTabIndex === ListViewMode.TABLE) {
            this.dataItems$.next([]);
            this.getItems$.next({});
          } else {
            this.currentTab = index;
          }

          this._previousSelectedTabIndex = index;

          this.checkIfScrollable();
        })
      )
      .subscribe();

    return this._interceptTabChangeSubscription;
  }

  @HostListener('window:resize') windowResize(): void {
    this.checkIfScrollable();
  }

  public checkIfScrollable(): void {
    this._cd.detectChanges();

    this.showBackToTopButton =
      (this.currentTab === ListViewMode.GALLERY &&
        this.galleryWrapperScrollable?.first &&
        this.galleryWrapperScrollable.first?.getElementRef().nativeElement.scrollHeight >
          this.galleryWrapperScrollable.first?.getElementRef().nativeElement.clientHeight) ||
      ((this.currentTab === ListViewMode.LIST || !this.tabGroup.first) &&
        this.matActionListScrollable?.first &&
        this.matActionListScrollable.first?.getElementRef().nativeElement.scrollHeight >
          this.matActionListScrollable.first?.getElementRef().nativeElement.clientHeight);

    this._cd.detectChanges();
  }

  public ngOnDestroy(): void {
    this._destroyEmitter.next();
  }

  public filterCategories(clear = false): void {
    this._itemCategoryTreeService.filterCategories(clear).then(() => this.getItems$.next({}));
  }

  public trackByFunction(index: number, value: never): number {
    return this.trackByPropertyName ? value[this.trackByPropertyName] : index;
  }

  public scrollToTop(instant = false): void {
    if (this.currentTab === ListViewMode.GALLERY) {
      this.galleryWrapperScrollable?.first?.scrollTo({ top: 0, behavior: instant ? 'auto' : 'smooth' });
    } else if ((this.currentTab === ListViewMode.LIST || !this.tabGroup?.first) && this.matActionListScrollable?.first) {
      this.matActionListScrollable?.first?.scrollTo({ top: 0, behavior: instant ? 'auto' : 'smooth' });
    }
  }

  public restoreScrollPosition(routerEventUrl?: string): void {
    if (this.galleryWrapperScrollable?.first && this._lastGalleryScrollOffset > 0) {
      this.galleryWrapperScrollable.first.scrollTo({ top: this._lastGalleryScrollOffset });
    }

    if (this.matActionListScrollable?.first && this._lastListScrollOffset > 0) {
      this.matActionListScrollable.first.scrollTo({ top: this._lastListScrollOffset });
    }

    if (routerEventUrl && routerEventUrl.includes(this._scrollCacheRoute) && !routerEventUrl.includes(this._scrollCacheRoute + '/')) {
      const syncfusionGridContent = (this._elementRef.nativeElement as HTMLElement)?.querySelector('ejs-grid .e-content');
      if (syncfusionGridContent) {
        syncfusionGridContent.scrollTop = this._lastSyncfusionGridScrollOffset;
        this._lastSyncfusionGridScrollOffset = 0;
      }

      const syncfusionTreegridContent = (this._elementRef.nativeElement as HTMLElement)?.querySelector('ejs-treegrid .e-content');
      if (syncfusionTreegridContent) {
        syncfusionTreegridContent.scrollTop = this._lastSyncfusionGridScrollOffset;
        this._lastSyncfusionGridScrollOffset = 0;
      }
    }
  }

  private _getLimit(tabIndex?: ListViewMode): number {
    let limit = 12;

    if (tabIndex === null || tabIndex === undefined) {
      tabIndex = this.selectedTabIndex;
    }

    if (tabIndex === null || tabIndex === undefined) {
      tabIndex = this.currentTab;
    }

    if (tabIndex === null || tabIndex === undefined) {
      tabIndex = this.settingsService.settingsChange$.value.TabIndex;
    }

    if (!this.appService.isMobile && tabIndex >= 0 && !this.showOnlyGalleryView) {
      switch (tabIndex) {
        case ListViewMode.GALLERY:
        case ListViewMode.LIST:
          break;
        case ListViewMode.TABLE:
          limit = 1000;

          if (!this.gridHasLimit) {
            limit = null;
          }
          break;
        default:
          break;
      }
    }

    return limit;
  }

  tabChangeAnimationDone(tabGroup: MatTabGroup): void {
    // TODO: implement loading queue so earlier stopped loadings wont affect stuff that currently still needs loading

    if (!this._isFetchingData$.value) {
      this.loadingService.isLoading$.next(false);
    }

    this.currentTab = tabGroup.selectedIndex;
    this.checkIfScrollable();
  }

  private _getItems(
    searchTerm: string = this.lastSearchTerm,
    showMore = false,
    categoryFilter: string[] = this._itemCategoryTreeService.checklistSelection.selected.map((category) => category.CategoryPathName)
  ): Observable<IShowMoreDataItem<T>> {
    this._searchTermChanged = this.lastSearchTerm !== searchTerm;
    this.lastSearchTerm = searchTerm;

    this.loadingService.isLoading$.next(true);
    this._isFetchingData$.next(true);

    let searchFunction$: Observable<IShowMoreDataItem<T>>;

    if (this.itemSearchFunction$) {
      searchFunction$ = this.itemSearchFunction$(searchTerm, showMore ? this.dataItems$.value.length + this._getLimit() : this._getLimit(), categoryFilter);
    } else {
      searchFunction$ = of(this.dataItems).pipe(
        map((dataItems) => {
          return {
            dataItems: dataItems.slice(0, showMore ? this.dataItems$.value.length + this._getLimit() : this._getLimit()),
            rowCount: this.dataItems.length,
          };
        })
      );
    }

    if (!searchFunction$ && !this.dataItems) {
      return throwError(() => new Error('itemSearchFunction$ or dataItems Input must be provided to the show-more.component!'));
    }

    return this._errorHandlingService.handleErrorWithBannerAndRetry({
      endpoint: searchFunction$,
      propertyModification: (dataWithRowCount) => {
        this.dataItems$.next(dataWithRowCount.dataItems);
        this.totalRowCount = dataWithRowCount.rowCount;

        this.loadingService.isLoading$.next(false);
        this._isFetchingData$.next(false);

        if (this._searchTermChanged) {
          this._searchTermChanged = false;
          this.scrollToTop(true);
        }

        this.checkIfScrollable();
      },
      bannerTitle: this.fetchItemsErrorMessage,
    });
  }
}
