import { Component } from '@angular/core';
import { ItemCategoryTreeService } from '../../services/item-category-tree.service';

@Component({
  selector: 'portal-item-category-tree',
  templateUrl: './item-category-tree.component.html',
  styleUrls: ['./item-category-tree.component.scss'],
})
export class ItemCategoryTreeComponent {
  constructor(public itemCategoryTreeService: ItemCategoryTreeService) {}
}
