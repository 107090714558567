import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatIconModule } from '@angular/material/icon';
import { MatListModule } from '@angular/material/list';
import { MatMenuModule } from '@angular/material/menu';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';
import { MatTreeModule } from '@angular/material/tree';
import { ContextualSearchModule } from '../contextual-search/contextual-search.module';
import { GalleryViewModule } from '../gallery-view/gallery-view.module';
import { ShowMoreComponent } from './show-more.component';
import { ItemCategoryTreeComponent } from './item-category-tree/item-category-tree.component';
import { MarkerSketchModule } from '../marker-sketch/marker-sketch.module';
import { HideWhileLoadingDirective } from '../loading/hide-while-loading-directive/hide-while-loading.directive';

@NgModule({
  declarations: [ShowMoreComponent, ItemCategoryTreeComponent],
  imports: [
    CommonModule,
    MatTabsModule,
    GalleryViewModule,
    MatIconModule,
    MatTooltipModule,
    ScrollingModule,
    MatListModule,
    MatButtonModule,
    ContextualSearchModule,
    MatTreeModule,
    MatMenuModule,
    MatCheckboxModule,
    MarkerSketchModule,
    HideWhileLoadingDirective,
  ],
  exports: [ShowMoreComponent],
})
export class ShowMoreModule {}
