/* eslint-disable @typescript-eslint/no-deprecated */
import { SelectionModel } from '@angular/cdk/collections';
import { FlatTreeControl } from '@angular/cdk/tree';
import { Injectable } from '@angular/core';
import { MatTreeFlatDataSource, MatTreeFlattener } from '@angular/material/tree';
import { ActivatedRoute, Router } from '@angular/router';
import { ICategoryFlatNode, ICategoryNode } from '../interfaces/dropdown-values.interface';

@Injectable({
  providedIn: 'root',
})
export class ItemCategoryTreeService {
  public flatNodeMap: Map<ICategoryFlatNode, ICategoryNode> = new Map<ICategoryFlatNode, ICategoryNode>();
  public nestedNodeMap: Map<ICategoryNode, ICategoryFlatNode> = new Map<ICategoryNode, ICategoryFlatNode>();
  public treeControl: FlatTreeControl<ICategoryFlatNode>;
  public treeFlattener: MatTreeFlattener<ICategoryNode, ICategoryFlatNode>;
  public treeFlatDataSource: MatTreeFlatDataSource<ICategoryNode, ICategoryFlatNode>;
  public checklistSelection: SelectionModel<ICategoryFlatNode> = new SelectionModel<ICategoryFlatNode>(true);

  constructor(
    private _router: Router,
    private _activatedRoute: ActivatedRoute
  ) {
    this.treeFlattener = new MatTreeFlattener<ICategoryNode, ICategoryFlatNode>(this.transformer, this.getLevel, this.isExpandable, this.getChildren);
    this.treeControl = new FlatTreeControl<ICategoryFlatNode>(this.getLevel, this.isExpandable);
    this.treeFlatDataSource = new MatTreeFlatDataSource<ICategoryNode, ICategoryFlatNode>(this.treeControl, this.treeFlattener);
  }

  public getLevel = (node: ICategoryFlatNode): number => node.Level;

  public isExpandable = (node: ICategoryFlatNode): boolean => node.Expandable;

  public getChildren = (node: ICategoryNode): ICategoryNode[] => node.CategoryChildren;

  public hasChild = (_: number, nodeData: ICategoryFlatNode): boolean => nodeData.Expandable;

  public transformer = (node: ICategoryNode, level: number): ICategoryFlatNode => {
    const existingNode: ICategoryFlatNode = this.nestedNodeMap.get(node);
    let flatNode: ICategoryFlatNode;

    if (existingNode && existingNode.CategoryName === node.CategoryName) {
      flatNode = existingNode;
    } else {
      flatNode = {
        CategoryName: node.CategoryName,
        Level: level,
        Expandable: !!node.CategoryChildren?.length,
        CategoryPathName: node.CategoryPathName,
      };
    }

    this.flatNodeMap.set(flatNode, node);
    this.nestedNodeMap.set(node, flatNode);

    return flatNode;
  };

  public descendantsAllSelected(node: ICategoryFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    return (
      descendants.length > 0 &&
      descendants.every((child) => {
        return this.checklistSelection.isSelected(child);
      })
    );
  }

  public descendantsPartiallySelected(node: ICategoryFlatNode): boolean {
    const descendants = this.treeControl.getDescendants(node);
    const hasSelectedChild = descendants.some((child) => this.checklistSelection.isSelected(child));

    return hasSelectedChild && !this.descendantsAllSelected(node);
  }

  public categorySelectionToggle(node: ICategoryFlatNode): void {
    this.checklistSelection.toggle(node);
    const descendants = this.treeControl.getDescendants(node);
    if (this.checklistSelection.isSelected(node)) {
      this.checklistSelection.select(...descendants);
    } else {
      this.checklistSelection.deselect(...descendants);
    }

    // Force update for the parent
    descendants.forEach((child) => {
      this.checklistSelection.isSelected(child);
    });

    this.checkAllParentsSelection(node);
  }

  public categoryLeafSelectionToggle(node: ICategoryFlatNode): void {
    this.checklistSelection.toggle(node);

    this.checkAllParentsSelection(node);
  }

  public checkAllParentsSelection(node: ICategoryFlatNode): void {
    let parent: ICategoryFlatNode | null = this.getParentNode(node);
    while (parent) {
      this.checkRootNodeSelection(parent);
      parent = this.getParentNode(parent);
    }
  }

  public checkRootNodeSelection(node: ICategoryFlatNode): void {
    const isSelectedNode = this.checklistSelection.isSelected(node);
    const descendants = this.treeControl.getDescendants(node);
    const hasAllDecendantsSelected =
      descendants.length > 0 &&
      descendants.every((child) => {
        return this.checklistSelection.isSelected(child);
      });

    if (isSelectedNode && !hasAllDecendantsSelected) {
      this.checklistSelection.deselect(node);
    } else if (!isSelectedNode && hasAllDecendantsSelected) {
      this.checklistSelection.select(node);
    }
  }

  public getParentNode(node: ICategoryFlatNode): ICategoryFlatNode | null {
    const currentLevel = this.getLevel(node);

    if (currentLevel < 1) {
      return null;
    }

    const startIndex = this.treeControl.dataNodes.indexOf(node) - 1;

    for (let i = startIndex; i >= 0; i--) {
      const currentNode = this.treeControl.dataNodes[i];

      if (this.getLevel(currentNode) < currentLevel) {
        return currentNode;
      }
    }

    return null;
  }

  public filterCategories(clear = false): Promise<boolean> {
    if (clear) {
      this.checklistSelection.clear();
    }

    return this._router.navigate([], {
      queryParamsHandling: 'merge',
      queryParams: {
        categories: this.checklistSelection.selected.length > 0 ? this.checklistSelection.selected.map((category) => category.CategoryPathName) : undefined,
      },
      relativeTo: this._activatedRoute,
      replaceUrl: true,
      state: { skipFade: true },
    });
  }
}
